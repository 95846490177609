<template>
  <div>
    <div style="font-size: 19px; font-weight: bolder; color: orangered">
      各专业作答情况表
    </div>
    <table class="dttable">
      <tr>
        <td>专业名称</td>
        <td>专业人数</td>
        <td>答题人数</td>
        <td>作答率</td>
      </tr>
      <tr v-for="(item, i, j) in majorAll" :key="j">
        <td>{{ i }}</td>
        <td>{{ item.total }}</td>
        <td>{{ item.join }}</td>
        <td>{{ parseInt((item.join / item.total) * 10000) / 100 }}%</td>
      </tr>
<!-- 
      <tr v-for="(item, i, j) in majorFl" :key="j">
        <td>{{ item.zy_type }}</td>
        <td>{{ item.total }}</td>
        <td>{{ item.count }}</td>
        <td>{{ parseInt((item.count / item.total) * 10000) / 100 }}%</td>
      </tr> -->
    </table>

    <div v-for="(tjtm, idx) in tjTmlist" :key="idx" style="border: 1px solid #4dadea; margin-top: 10px">
      <div style="font-size: 19px; font-weight: bolder; color: orangered">
        {{ tjtm.tm_title }}
      </div>

      <div style="margin-bottom: 100px"
        v-if="tjtm.tm_type.indexOf('radio') >= 0 || tjtm.tm_type.indexOf('checkbox') >= 0">
        <table class="dttable">
          <tr>
            <td rowspan="2">题目</td>
            <td rowspan="2">选项</td>
            <td rowspan="2">所占百分比</td>
            <td rowspan="2">选择人数</td>
            <td colspan="4">系部</td>
            <td colspan="6">专业类别</td>
          </tr>
          <tr>
            <td v-for="(m, i, j) in majorAll" :key="j">{{ i }}</td>
            <td v-for="(m, i, j) in majorFl" :key="j">{{ m.zy_type }}</td>
          </tr>
          <template v-for="(xx, i1) in tjtm.tm_content">
            <tr>
              <td v-if="i1 == 0" :rowspan="tjtm.tm_content.length">
                {{ tjtm.tm_title }}
              </td>
              <td>{{ xx.title }}</td>
              <td>{{ parseInt((xx.choose_count / tjtm.join) * 10000) / 100 }}</td>
              <td>{{ xx.choose_count }}</td>

              <template v-for="(a, b, c) in xx.majorAll">
                <td>{{ xx.majorAll[b].join }}</td>
              </template>

              <template v-for="(a, b, c) in xx.majorFl">
                <td>{{ xx.majorFl[b].count }}</td>
              </template>
            </tr>
          </template>
          <tr v-if="tjtm.join > 0">
            <td colspan="3">合计</td>
            <td>{{ tjtm.join }}</td>

            <template v-for="(a, b, c) in tjtm.majorAll">
              <td>{{ tjtm.majorAll[b].join }}</td>
            </template>
            <template v-for="(a, b, c) in tjtm.majorFl">
              <td>{{ tjtm.majorFl[b].count }}</td>
            </template>
          </tr>
        </table>
      </div>
      <div>
        <table>
          <tr>
            <td colspan="8">
              <span v-for="(a, b, c) in tjtm.ansDic" :key="c">{{ b }}:{{ tjtm.ansDic[b] }}人;</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      plan_id: 0,
      ivst_id: 0,
      planInfo: {},
      tjTmlist: [],
      gkInfo: {},
      laodok: false,
      AllStudents: [],
      StuDic: {},
      txtPie: [],
      majors: {
        护理系: 0,
        医学技术系: 0,
        健康服务系: 0,
      },
      majorTotal: { 护理系: 0, 医学技术系: 0, 健康服务系: 0 },
      majorAll: { 护理系: 0, 医学技术系: 0, 健康服务系: 0 },
      majorFl: [],
    };
  },
  mounted() {
    this.plan_id = this.$route.query.plan_id;
    this.getGK();
  },
  methods: {
    getGK() {
      this.$http
        .post("/api/ivst_plan", {
          plan_id: this.plan_id,
          edit: 1,
        })
        .then((res) => {
          this.planInfo = res.data;
        });
      this.$http
        .post("/api/major_type_students", {
          plan_id: this.plan_id,
        })
        .then((fl) => {
          this.majorFl = fl.data;
          this.$http
            .post("/api/get_ivst_tj_gk", {
              plan_id: this.plan_id,
            })
            .then((res) => {
              let gkInfo = res.data;

              gkInfo.percent =
                parseInt((gkInfo.finish_ok / gkInfo.stu_total) * 10000) / 100;
              this.gkInfo = gkInfo;

              this.getTongji();
            });
        });
    },
    getTongji() {
      this.$http
        .post("/api/get_ivst_tj_tm", {
          plan_id: this.plan_id,
        })
        .then((res) => {
          //   for (let item of res.data) {
          //     // let arr = item.tm_content.sort(function (a, b) {
          //     // 	return a.choose_count - b.choose_count
          //     // })
          //     // item.tm_content = arr;

          //   }
          //重新获取专业字典
          let marjors = res.data[0].marjors;
          let majorAll = {},
            classAll = {};
          for (let m of marjors) {
            if (!majorAll[m.major_name]) {
              majorAll[m.major_name] = {
                major_name: m.major_name,
                total: 0,
                join: 0,
              };
            }
            if (!classAll[m.class_id]) {
              classAll[m.class_id] = m;
            }
          }

          let students = res.data[0].all_stu;
          let AllStudents = {};
          for (let m of students) {
            if (!AllStudents[m.id]) {
              AllStudents[m.id] = m;
            }
          }

       
          for (let stu of students) {
            majorAll[classAll[AllStudents[stu.id].class_id].major_name].total++;
          }
         
          for (let stu_id in res.data[0].students) {
            
             try {
              majorAll[classAll[AllStudents[stu_id].class_id].major_name].join++;
             } catch (error) {
              
             }
          }
          let tempMajor = JSON.parse(JSON.stringify(majorAll));
          for (let k in tempMajor) {
            tempMajor[k].total = 0;
            tempMajor[k].join = 0;
          }

          //界别
          let gkmajorFl = JSON.parse(JSON.stringify(this.majorFl));
          for (let tm of res.data) {
            if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {
              tm.majorAll = JSON.parse(JSON.stringify(tempMajor));
              for (let xx of tm.tm_content) {
                xx.majorAll = JSON.parse(JSON.stringify(tempMajor));
                for (let stuid of xx.students) {
                 try {
                  xx.majorAll[classAll[AllStudents[stuid].class_id].major_name].join++;
                  tm.majorAll[classAll[AllStudents[stuid].class_id].major_name].join++;
                 } catch (error) {
                  
                 }
                }
              }
              //设置专业类别

              tm.majorFl = JSON.parse(JSON.stringify(this.majorFl));
              for (let xx of tm.tm_content) {
                xx.majorFl = JSON.parse(JSON.stringify(this.majorFl));
                for (let stu of xx.students) {
                  for (let i = 0; i < xx.majorFl.length; i++) {
                    if (xx.majorFl[i].students[stu]) {
                      xx.majorFl[i].count++;
                      tm.majorFl[i].count++;
                      if (tm.tm_title.indexOf("您现在的情况") >= 0) {
                        gkmajorFl[i].count++;
                      }


                    }
                  }
                }
              }



            } else {
              if (tm.tm_title.indexOf("平均年收入(税后)") > 0) {
                for (let a of tm.answers) {
                  let salary = tm.answers[0];
                  if (isNaN(salary)) {
                    //console.log(salary)
                  } else {
                    salary = parseInt(salary);
                    if (salary < 100) {
                      salary = salary * 1000;
                    }
                    tm.answers[0] = salary;
                  }
                }
              }

              let ansDic = {};
              for (let a of tm.answers) {

                if (!ansDic[a.answers[0]]) {
                  ansDic[a.answers[0]] = 1;
                } else {
                  ansDic[a.answers[0]]++;
                }
              }
              tm.ansDic = ansDic;
            }
          }

          this.tjTmlist = res.data;

          this.majorAll = majorAll;

          for (let mj of gkmajorFl) {
            mj.total = Object.keys(mj.students).length
          }
          this.majorFl = gkmajorFl


          this.$nextTick(() => {
            this.laodok = true;
          });
        });
    },
  },
};
</script>

<style scoped="scoped">
.boxitem {
  width: 220px;
  height: 90px;
  display: inline-block;

  background-size: 100% 100%;
  margin-bottom: 20px;
  color: #4dadea;
  font-size: 16px;
  background-color: #ecf5fc;
  border-radius: 8px;
  position: relative;
}

.boxitem img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0;
}

.boxdesc {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
}
</style>
